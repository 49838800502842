import React, { useEffect } from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';

import Header from '../components/header';
import HeaderContent from '../components/values/headerContent';
import UnderHeaderSection from '../components/values/underHeaderSection';
import FirstSection from '../components/values/firstSection';
import SecondSection from '../components/values/secondSection';
import ThidrSection from '../components/values/thirdSection';
import FourthSection from '../components/values/fourthSection';
import FifthSection from '../components/values/fifthSection';
import SixthSection from '../components/values/sixthSection';
import SevenhSection from '../components/values/seventhSection';
import EighthSection from '../components/values/eighthSection';
import NinthSection from '../components/values/ninthSection';
import TopBtn from '../components/inc/topBtn';

import Footer from '../components/footer';

const ValuesPage = () => {
  useEffect(() => {
    window.addEventListener('scroll', function () {
      window.AOS.init({
        duration: 500,
      });
    });

    return () => {
      window.removeEventListener('scroll', function () {
        window.AOS.init({
          duration: 500,
        });
      });
    };
  }, []);

  return (
    <>
      <Seo />
      <Header>
        <HeaderContent />
      </Header>
      <UnderHeaderSection />
      <main>
        <FirstSection />
        <SecondSection />
        <ThidrSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <SevenhSection />
        <EighthSection />
        <NinthSection />
      </main>
      <Footer />
      <TopBtn />
    </>
  );
};

export default ValuesPage;
