import * as React from 'react';

import Layout from '../../layout';

import SectionBg from '../../../images/__new/values/img9.jpg';

const NinthSection = () => {
  return (
    <section className='valuesNinthSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              The only true music has no specific style. Great music is
              inlufenced by all styles of music.
              <br />
            </p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default NinthSection;
