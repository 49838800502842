import * as React from 'react';

import HeroImg from '../../../../src/images/__new/values/img1.jpg';

const HeaderContent = (props) => {
  return (
    <div className={'headerContent valuesHeaderContent'}>
      <div className={'heroImage'}>
        <img src={HeroImg} alt={'Saxophonist'} />
      </div>
    </div>
  );
};

export default HeaderContent;
