import * as React from 'react';

import Layout from '../../layout';

import SectionBg from '../../../images/__new/values/img8.jpg';

const EighthSection = () => {
  return (
    <section className='valuesEighthSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              <span className='yellowText'>
                YOUR ONLY LIMIT
                <br />
                IS YOUR HEAD.
                <br />
                NOT YOUR FINGERS.
                <br />
              </span>
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
      <div className='textSection'>
        <Layout>
          <div className='sectionDesctiption'>
            <p className='text textBold'>
              Responsibility towards one of the most influential instruments of
              all time: we want to take the saxophone out of its corner position
              and place it where it has belonged all along: on the world stage
              of music — all over the world.
            </p>
            <p className='text textBold'>
              Whether classical concert hall, experimental theater stage,
              stadium or rehearsal room of a young Hip Hop-Indie-Reggae band.
              The saxophone is part of every style of music and every stage —
              and we put it into the hands of the musicians so that they can
              combine their styles and mutually influence each other.
            </p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default EighthSection;
