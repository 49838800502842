import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import BgImage from '../../../images/__new/values/img7.jpg';

const SeventhSection = () => {
  return (
    <section className={'valuesSeventhSection'}>
      <LeftRight className={'firstLeftRightSection'}>
        <Layout>
          <Left>
            <div className='sectionDescription'>
              <p className='text textBold textMarginBottom'>
                GRADIVARI wants to pass on music from generation
                to&nbsp;generation:
                <br />
                we want to support professional musicians, performing musicians
                and those who want to become musicians. We want to give them the
                necessary tools, strategies and experience to develop their
                musical skills and thus pass on their music — from generation to
                generation.
              </p>
              <p className='text textBold textMarginBottom'>
                Because we are convinced: the only true music has no specific
                style. Great music is influenced by all styles of music.
              </p>
              <p className='text textBold textMarginBottom'>
                We want music that has been fascinating us people for centuries
                and that inspires people from classical music to jazz, so that
                they too can contribute to keeping the love of music and
                instruments alive and pass it on.
              </p>
              <p className='text '>
                <span className='textBold'>
                  Responsibility towards the next generation of musicians:
                </span>
                we want to bring young people closer to music and to their
                instruments, to show them that music enriches their lives. We
                are convinced that music strengthens and develops our
                personalities and character. We want to inspire young musicians
                to use their music to enrich the lives of other people and to
                share it with the world. With <strong>GRADIVARI</strong>, we
                want to help young people to master musical challenges much
                faster and to achieve musical success.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img
                src={BgImage}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </section>
  );
};

export default SeventhSection;
