import * as React from 'react';

import Layout from '../../layout';

const underHeaderSection = (props) => {
  return (
    <div className={'underHeaderSection'}>
      <Layout>
        <div className='firstBuble'>
          <p className='bubleContent'>
            CONNECTION &<br />
            RESPONSIBILITY
          </p>
        </div>
        <div className='secondBuble'>
          <p className='bubleContent'>MOTIVATION</p>
        </div>
        <div className='thirdBuble'>
          <p className='bubleContent'>COURAGE</p>
        </div>
      </Layout>
    </div>
  );
};

export default underHeaderSection;
