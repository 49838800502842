import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import FirstImage from '../../../images/__new/values/oval_photo_1.png';
import SecondImage from '../../../images/__new/values/img2.jpg';

const FirstSection = () => {
  return (
    <section className={'valuesFirstSection'}>
      <LeftRight className={'firstLeftRightSection'}>
        <Layout>
          <Left>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                <span className='yellowText'>Courage</span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                Every artist — whether writer, painter, dancer, actress or
                musician — has to take a big risk. An artist must pursue his
                goal with passion, so that he can turn his art into a profession
                and manage his life accordingly. This&nbsp;requires courage. A
                lot of courage. And a great vision, which the artist must pursue
                with all his passion and discipline. The artist must believe in
                his vision and his goals. He has to realize them, even if he has
                to risk everything — this is the only way he can create
                extraordinary things and achieve his goals.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img
                src={FirstImage}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <LeftRight className={'secondLeftRightSection'}>
        <Layout>
          <Left className=''>
            <div className='imageContainer'>
              <img
                src={SecondImage}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Left>
          <Right>
            <div className='sectionDescription'>
              <p className='text'>
                <strong>Courage to create something new</strong> To create
                something new and extraordinary, you need courage and
                perseverance. Something that did not exist before, that is
                unusual, is often viewed skeptically at first and quickly
                condemned. How many great songs would not exist today if
                musicians had listened to the critics and the discouraged?
                Everything new scares us humans at first — and then it changes
                the world. We want to motivate musicians to play against the
                current...
              </p>
              <p className='text'>
                in other words... to swim, to try new things, to experiment, to
                get together with other musicians from other genres and to
                create something extraordinary. The sax does not belong in the
                orchestra? Let us open the doors of the orchestra wide, sweep
                away academic rules and break through boundaries.
              </p>
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </section>
  );
};

export default FirstSection;
