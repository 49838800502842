import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/values/img4.jpg';
import SectionBg2 from '../../../images/__new/values/img5.png';

const FourthSection = () => {
  return (
    <section className='fourthValuesSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              STOP DREAMING.
              <br /> START PLAYING
              <br /> THE UNPLAYABLE.
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
      <div className='secondSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg2}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='sectionTitle'>
            <p className='sectionTitleText'>
              RISE ABOVE YOUR BOUNDARIES. <br /> RISE ABOVEYOUR DOUBTS.
              <br /> RISE ABOVE YOUR LIMITS.
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FourthSection;
