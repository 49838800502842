import * as React from 'react';

import Layout from '../../layout';

import SectionBg from '../../../images/__new/values/img3.jpg';

const SecondSection = () => {
  return (
    <section className='valuesSecondSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              BELIEVE IN YOUR VISION, BELIEVE IN YOUR GOALS AND GO FOR THEM,
              EVEN&nbsp;IF YOU HAVE TO
              <span className='yellowText'>RISK EVERYTHING.</span>
            </p>
            <p className='underTItleText'>Mateo Granić (GRADIVARI Founder)</p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default SecondSection;
