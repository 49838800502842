import * as React from 'react';

import Layout from '../../layout';

import SectionBg from '../../../images/__new/values/img6.jpg';

const SixthSection = () => {
  return (
    <section className='valuesSixthSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              <span className='yellowText'>Responsibility</span>
            </p>
            <p className='text'>
              <span className='textBold'>
                Responsibility towards our homeland and its people:
              </span>
              In the future, we will produce some parts of our products in
              Croatia — in the home country of our founder, Mateo Granić. In
              return, we will invest in the landscape and in the growth of the
              country to create jobs for the local people who help us to produce
              excellent products. Responsibility towards our planet: we think
              and act sustainably. In the future, we want to manufacture our
              products as sustainably as we can. With our responsibility towards
              our country and our planet, we want to give something back to the
              people and the place from which we extract valuable resources.
            </p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default SixthSection;
