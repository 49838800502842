import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import BgImage from '../../../images/__new/values/oval_photo_6.png';

const FifthSection = () => {
  return (
    <section className={'valuesFifthSection'}>
      <LeftRight className={'firstLeftRightSection'}>
        <div className='bgRectangle'></div>
        <Layout>
          <Left>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                <span className='yellowText'>Connection</span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                Connections are based on credibility, shared interests, goals
                and values. The value "connection" in action at GRADIVARI:
                Connection with our customers: we feel connected with our
                audience, our customers, because we are musicians ourselves. We
                think like them, we know their wishes, their goals and their
                motivations, precisely because we have all these things in
                common with them.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img
                src={BgImage}
                alt='Saxophonist'
                className='image'
                loading='lazy'
              />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <div className='textSection'>
        <Layout>
          <div className='sectionDescription'>
            <p className='text'>
              <span className='textBold'>The love for music unites:</span>
              Music connects. Music should have no boundaries and be subject to
              no limitations. Music is free and liberated. Music brings people
              together from all over the world. Musicians of every genre should
              play together in future, inspire and motivate each other. We want
              to bring people and music together.
            </p>
            <p className='text'>
              Through the connection with the saxophone, with the audience, with
              the music — to become one, even if it is for just a moment. To
              build a connection to the audience, to pass on the healing effect
              of music (stress reduction).
            </p>
            <p className='text'>
              <span className='textBold'>
                GRADIVARI supports the idea of community:
              </span>
              Music needs freedom. Music is longing for exchange and
              inspiration. We help to strengthen the exchange between musicians.
              We play against elitism in classical music.
            </p>
            <p className='text smallParagraph'>
              An interaction, a community of musicians, ergo, no competitive
              spirit, no ego, but a community, a strong community where the
              music of each individual musician can grow and develop freely —
              strengthened by the community.
            </p>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FifthSection;
