import * as React from 'react';

import Layout from '../../layout';
import LeftRight from '../../inc/leftRight';
import Left from '../../inc/leftRight/left';
import Right from '../../inc/leftRight/right';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import BgImage1 from '../../../images/__new/values/oval_photo_2.png';
import BgImage2 from '../../../images/__new/values/oval_photo_3.png';
import BgImage3 from '../../../images/__new/values/oval_photo_5.png';
import BgImage4 from '../../../images/__new/values/oval_photo_4.png';

const ThirdSection = () => {
  return (
    <section className={'valuesThirdSection'}>
      <LeftRight className={'firstLeftRightSection'}>
        <div className='bgRectangle'></div>
        <Layout>
          <Left>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                <span className='yellowText'>Motivation</span>
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='text'>
                We can only motivate other people if we are absolutely convinced
                of something, of an idea or a vision.
              </p>
              <p className='text'>
                We are absolutely convinced of the energy that music can release
                to change things — just like we are convinced of our vision to
                enable musicians to free themselves to get the most out of
                themselves and their instrument.
              </p>
              <p className='text'>
                We want to motivate young musicians and experienced musicians to
                pursue and realize their goals — whether small or big. We will
                help you to do so, to achieve great success when playing and to
                do so much faster.
              </p>
              <p className='text'>
                We motivate musicians to overcome their obstacles and to turn
                their musical visions into reality. We are convinced: if you can
                hear the music in your head, then you can turn it into reality.
                We give musicians the tools for just that.
              </p>
            </div>
          </Left>
          <Right>
            <div className='imageContainer'>
              <img
                src={BgImage1}
                alt='Saxophonist'
                className='image1'
                loading='lazy'
              />
              <img
                src={BgImage2}
                alt='Saxophonist'
                className='image2'
                loading='lazy'
              />
            </div>
          </Right>
        </Layout>
      </LeftRight>
      <LeftRight className={'secondLeftRightSection'}>
        <div className='bgRectangle'></div>
        <Layout>
          <Left>
            <div className='imageContainer'>
              <img
                src={BgImage3}
                alt='Saxophonist'
                className='image1'
                loading='lazy'
              />
              <img
                src={BgImage4}
                alt='Saxophonist'
                className='image2'
                loading='lazy'
              />
            </div>
          </Left>
          <Right>
            <div className='sectionDescription'>
              <p className='textBig'>
                We support musicians in surpassing their musical expectations
                and pursuing development to reach their fullest potential.
              </p>
              <p className='text'>
                We want to break down the boxes in people's minds and mix
                musical genres. We want to give musicians all over the world the
                space to enter new musical worlds, to explore unusual musical
                styles, to grow and develop so that their music knows no
                boundaries, no genres and to only accepts "yes I can"!
              </p>
              <p className='text'>
                Music for everyone. Let us free classical music from its
                barriers. Let us make the unplayable playable.
              </p>
            </div>
          </Right>
        </Layout>
      </LeftRight>
    </section>
  );
};

export default ThirdSection;
